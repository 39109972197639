import "react-phone-number-input/style.css";
import "./App.css";
import type { RouteObject } from "react-router-dom";
import { Link, useRoutes } from "react-router-dom";
import Home from "./pages/Home";
import { Box, useColorModeValue } from "@chakra-ui/react";
import Signin from "./pages/Signin";
import EnterVerificationPage from "./pages/EnterVerificationCodePage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import AppLayout from "./components/Layouts";
import NewAppLayout from "./components/Layouts/NewAppLayut";
import HomePage from "./components/Layouts/HomePage";
import SearchPage from "./pages/SearchPage";
import MyBucketList from "./pages/MyBucketList";
import TravelAdvisor from "./pages/TravelAdvisor";
import VoiceAdvisor from "./components/TravelAdvisor/VoiceAdvisor";
import { AppContextProvider } from "./context/AppContext";
import AuthCallback from "./pages/AuthCallback";
import BucketCardDetail from "./components/BucketCardDetail";
import Homepage from "./pages/Homepage";
import JoinWaitList from "./pages/JoinWaitList";
import Itinerary from "./pages/Itinerary";
import Collection from "./pages/Collection";
import MainScreen from "./components/Collection-flow/TravelAppUI";
import InfluencersVideo from "./components/InfluencerFlow/InfluencersVideo";
import Chat from "./components/TravelAdvisor/Chat";
import TripsHotel from "./pages/TripsHotel";
import ProfileView from "./components/Profile/ProfileView";
import NavigationTabs from "./components/CreateItinary/NavigationTabs";
import SavedConversation from "./components/TravelAdvisor/SavedConversation";
import StoryCarousel from "./components/Collection-flow/StoryCarousel";
import StoryCarousel2 from "./components/Collection-flow/StoryCarousel2";
import { SplitDevice } from "./pages/SplitDevice";
import MobileMapHighlightContextProvider from "./context/MobileMapContextProvider";
import { useLoading } from "./context/LoadingContext";
import Loader from "./components/Loader/Loader";
import Signup from "./pages/Signup";
import Approve from "./pages/Approve";

function App() {
  const allowedDomains = [
    "roamsta.live",
    "alpha.traverseai.live", //prod
    "dev.roamsta.live",
    "localhost",
    "192.168.1.108",
    "rahee.ai"
  ];
  const currentDomain = window.location.hostname;
  const isAllowedDomain = allowedDomains.includes(currentDomain);


  const { isLoading } = useLoading();

  let routes: RouteObject[] = 
  isAllowedDomain
    ? 
    [
      // {
      //   path: "/",
      //   element: <AppLayout />,
      //   children: [
      //     { index: true, element: <Homepage /> },
      //     { index: false, path: "join", element: <JoinWaitList /> },
      //   ],
      // },
      {
        path: "/",
        element: <NewAppLayout />,
        children: [
          { index: true, element: <SplitDevice /> },
          { index: false, path: "video_search", element: <SearchPage /> },
          { index: false, path: "navigate", element: <NavigationTabs /> },
          { index: false, path: "itineraries/:id", element: <Itinerary /> },
          { index: false, path: "mybucketlist", element: <MyBucketList /> },
          { index: false, path: "traveladvisor", element: <TravelAdvisor /> },
          { index: false, path: "voiceadvisor", element: <VoiceAdvisor /> },
          { index: false, path: "chat", element: <Chat /> },
          { index: false, path: "collection", element: <Collection /> },
          { index: false, path: "modal", element: <MainScreen /> },
          {
            index: false,
            path: "video-detail",
            element: <InfluencersVideo />,
          },
          {
            index: false,
            path: "savedConversation",
            element: <SavedConversation />,
          },
          {
            index: false,
            path: "story-carousel",
            element: <StoryCarousel />,
          },
          {
            index: false,
            path: "place/:id",
            element: <StoryCarousel2 />,
          },
          {
            index: false,
            path: "entity_video",
            element: <InfluencersVideo />,
          },
          {
            index: false,
            path: "trips/:origin/:destination",
            element: <TripsHotel />,
          },
          { index: false, path: "profile", element: <ProfileView /> },
          {
            index: false,
            path: "bucketCardDetail",
            element: <BucketCardDetail />,
          },
          {
            index: false,
            path: "signup",
            element: <Signup />
          },
          {
            index: false,
            path: ".approve",
            element: <Approve />,
          }
        ],
      },

      {
        index: true,
        path: "trips/:origin/:destination",
        element: <TripsHotel />,
      },
      { index: true, path: "/home/auth/:token", element: <AuthCallback /> },
      {
        path: "/home/mobile",
        element: <AppLayout />,
        children: [{ index: true, element: <Home /> }],
      },
      {
        path: "/verify",
        children: [{ index: true, element: <EnterVerificationPage /> }],
      },
      {
        path: "/verify/change-password",
        children: [{ index: true, element: <ChangePasswordPage /> }],
      },
      {
        path: "/signin",
        children: [{ index: true, element: <Signin /> }],
      },
      {
        path: "/influencer/homepage",
        children: [{ index: true, element: <HomePage /> }],
      },
      {
        path: "/forgot-password",
        children: [{ index: true, element: <ForgotPasswordPage /> }],
      },
      { path: "/*", element: <NoMatch /> },
    ]
    : [
      {
        path: "/",
        element: <AppLayout />,
        children: [
          { index: true, element: <Homepage /> },
          { index: false, path: "join", element: <JoinWaitList /> },
        ],
      },
      { path: "/*", element: <NoMatch /> },
    ];

  let element = useRoutes(routes);
  const bg = useColorModeValue("trBgColor", "trBackgroundColor");

  return (
    <Box
      bg={bg}
      overflowY={"auto"}
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      sx={{
        scrollbarWidth: "none",
      }}
      width={"100%"}
    >
      {" "}
      <AppContextProvider>
        <MobileMapHighlightContextProvider>
          {isLoading && <Loader />}
          {element}
        </MobileMapHighlightContextProvider>
      </AppContextProvider>
    </Box>
  );
}

export default App;

function NoMatch() {
  window.location.replace("/");
  return (
    <div>
      <h2>It looks like you're lost...</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
